body {
  font-family: sans-serif;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;

  font-size: 36px;
}

.dialog-title {
  font-size: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.dialog-content {
  font-size: 16px;
  padding-bottom: 12px;
}

.overlay .dialog {
  background-color: #fff;
  color: #333;
  padding: 6px 24px 24px 24px;
  border-radius: 10px;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.5);
  font-size: 1em;
}

.overlay .success-dialog {
  border: 4px solid rgb(0, 160, 0);
}

.overlay .error-dialog {
  border: 4px solid rgb(180, 0, 0);
}

.app-chrome {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button {
  cursor: pointer;
  font-size: 13px;
  line-height: 13px;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  min-width: 26px;
  min-height: 26px;
  border: 1px solid rgb(217, 217, 217);
  background-color: rgb(255, 255, 255);
  color: rgb(67, 67, 67);
  display: block;
  float: left;
  text-align: center;
  margin: 6px;
  padding: 0 6px;
  border-radius: 3px;
  transition: background-color .35s, border-color .35s, color .35s;
}

.button:hover {
  border: 1px solid rgb(180, 180, 180);
  background-color: rgb(220, 220, 220);
}

.app-contents {
  position: relative;
  height: 100%;
}

nav {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 4px;
}

nav button {
  margin: 0 4px;
}